import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/menu.vue'
// import Home from '../views/enterprisehome/enterindex/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
    // component: () => import('../views/enterprisehome/enterindex/index.vue')
  },
  {
    path: '/enterprisehome',
    name: 'enterprisehome',
    component: () => import('../views/enterprisehome/Home.vue'),
    children: [
      {
        path: '/qptitude',
        name: 'qptitude',
        component: () => import('../views/enterprisehome/components/qptitude.vue')
      },
      {
        path: '/introductory',
        name: 'introductory',
        component: () => import('../views/enterprisehome/components/introductory.vue')
      },
      {
        path: '/personmsg',
        name: 'personmsg',
        component: () => import('../views/enterprisehome/components/personmsg.vue')
      },
      {
        path: '/schoolmsg',
        name: 'schoolmsg',
        component: () => import('../views/enterprisehome/components/schoolmsg.vue')
      },
      {
        path: '/publicity',
        name: 'publicity',
        component: () => import('../views/enterprisehome/components/publicity.vue')
      }
    ]
  },
  {
    path: '/positionlist',
    name: 'positionlist',
    component: () => import('../views/enterprisehome/positionmanage/positionlist.vue')
  },
  // 发布职位
  {
    path: '/issueposition',
    name: 'issueposition',
    component: () => import('../views/enterprisehome/positionmanage/issueposition.vue')
  },
  // 学校职位列表
  {
    path: '/positionlistschool',
    name: 'positionlistschool',
    component: () => import('../views/schoolpage/positionmanage/positionlist.vue')
  },
  // 学校职位详情
  {
    path: '/positionviewschool',
    name: 'positionviewschool',
    component: () => import('../views/schoolpage/positionmanage/positionview.vue')
  },
  // 企业职位详情
  {
    path: '/positionview',
    name: 'positionview',
    component: () => import('../views/enterprisehome/positionmanage/positionview.vue')
  },
  // 学校职位审核列表页面
  {
    path: '/Auditlistschool',
    name: 'Auditlistschool',
    component: () => import('../views/schoolpage/positionmanage/Auditlist.vue')
  },
  // 学校职位审核详情
  {
    path: '/Auditlistdetailschool',
    name: 'Auditlistdetailschool',
    component: () => import('../views/schoolpage/components/Auditlistdetail.vue')
  },
  // 学校宣讲会线上审核详情
  {
    path: '/propauditdetailonline',
    name: 'propauditdetailonline',
    component: () => import('../views/schoolpage/components/propauditdetailonline.vue')
  },
  // 学校宣讲会线上审核详情
  {
    path: '/propauditdetail',
    name: 'propauditdetail',
    component: () => import('../views/schoolpage/components/propauditdetail.vue')
  },
  // 学校双选会会线上审核详情
  {
    path: '/doubleauditdetailonline',
    name: 'doubleauditdetailonline',
    component: () => import('../views/schoolpage/components/doubleauditdetailonline.vue')
  },
  // 学校双选会线下审核详情
  {
    path: '/doubleauditdetail',
    name: 'doubleauditdetail',
    component: () => import('../views/schoolpage/components/doubleauditdetail.vue')
  },
  // 学校企业列表
  {
    path: '/enterpriceschool',
    name: 'enterpriceschool',
    component: () => import('../views/schoolpage/enterpricepage/enterpriceschool.vue')
  },
  // 学校企业审核列表
  {
    path: '/enterauditlistschool',
    name: 'enterauditlistschool',
    component: () => import('../views/schoolpage/enterpricepage/Auditlist.vue')
  },
  // 学校宣讲会审核列表
  {
    path: '/propauditlistschool',
    name: 'propauditlistschool',
    component: () => import('../views/schoolpage/Propagandameeting/auditlist.vue')
  },
  // 学校双选会审核列表
  {
    path: '/doubleauditlistschool',
    name: 'doubleauditlistschool',
    component: () => import('../views/schoolpage/Doubleelection/auditlist.vue')
  },
  // 学校双选会职位列表
  {
    path: '/doublepositionlistschool',
    name: 'doublepositionlistschool',
    component: () => import('../views/schoolpage/Doubleelection/positionlist.vue')
  },
  // 学校数据统计
  {
    path: '/ScaleStatistical',
    name: 'ScaleStatistical',
    component: () => import('../views/schoolpage/statistics/ScaleStatistical.vue')
  },
  // 毕业生就业率统计
  {
    path: '/employmentStatistics',
    name: 'employmentStatistics',
    component: () => import('../views/schoolpage/statistics/employmentStatistics.vue')
  },
  // 毕业生毕业去向统计
  {
    path: '/careerDevelopment',
    name: 'careerDevelopment',
    component: () => import('../views/schoolpage/statistics/careerDevelopment.vue')
  },
  // 企业双选会职位列表
  {
    path: '/doublepositionlist',
    name: 'doublepositionlist',
    component: () => import('../views/enterprisehome/Doubleelection/positionlist.vue')
  },
  // 发布职位第二步
  {
    path: '/issuesecond',
    name: 'issuesecond',
    component: () => import('../views/enterprisehome/positionmanage/issuesecond.vue')
  },
  // 职位预览
  {
    path: '/positionpreview',
    name: 'positionpreview',
    component: () => import('../views/enterprisehome/positionmanage/positionpreview.vue')
  },
  // 人才管理列表
  {
    path: '/talentsadmin',
    name: 'talentsadmin',
    component: () => import('../views/enterprisehome/positionmanage/talentsadmin.vue')
  },
  // 企业空中双选会列表
  {
    path: '/airdoubleselectlist',
    name: 'airdoubleselectlist',
    component: () => import('../views/enterprisehome/airdouble/airdoubleselectlist.vue')
  },
  // 企业线下双选会列表
  {
    path: '/offinedoubleselectlist',
    name: 'offinedoubleselectlist',
    component: () => import('../views/enterprisehome/offinedouble/offinedoubleselectlist.vue')
  },
  // 企业空中线下宣讲会列表
  {
    path: '/airdoublepreachlist',
    name: 'airdoublepreachlist',
    component: () => import('../views/enterprisehome/airdouble/airdoublepreachlist.vue')
  },
  // 企业宣讲会列表
  {
    path: '/offinedoublepreachlist',
    name: 'offinedoublepreachlist',
    component: () => import('../views/enterprisehome/offinedouble/offinedoublepreachlist.vue')
  },
  // 学校空中双选会列表
  {
    path: '/airdoubleselectlistschool',
    name: 'airdoubleselectlistschool',
    component: () => import('../views/schoolpage/airdoubleschool/airdoubleselectlist.vue')
  },
  // 学校线下双选会列表
  {
    path: '/offinedoubleselectlistschool',
    name: 'offinedoubleselectlistschool',
    component: () => import('../views/schoolpage/offinedoubleschool/offinedoubleselectlist.vue')
  },
  // 学校空中宣讲会列表
  {
    path: '/airdoublepreachlistschool',
    name: 'airdoublepreachlistschool',
    component: () => import('../views/schoolpage/airdoubleschool/airdoublepreachlist.vue')
  },
  // 学校线下宣讲会列表
  {
    path: '/offinedoublepreachlistschool',
    name: 'offinedoublepreachlistschool',
    component: () => import('../views/schoolpage/offinedoubleschool/offinedoublepreachlist.vue')
  },
  // 企业发布线上宣讲会
  {
    path: '/issueairpreach',
    name: 'issueairpreach',
    component: () => import('../views/enterprisehome/issue/issueairpreach.vue')
  },
  // 企业发布线下宣讲会
  {
    path: '/issueoffinepreach',
    name: 'issueoffinepreach',
    component: () => import('../views/enterprisehome/issue/issueoffinepreach.vue')

  },

  // 学校发布线上双选会
  {
    path: '/issueairdouble',
    name: 'issueairdouble',
    component: () => import('../views/schoolpage/issueschool/issueairdouble.vue')
  },
  // 学校发布线下双选会
  {
    path: '/issueairpreachschool',
    name: 'issueairpreachschool',
    component: () => import('../views/schoolpage/issueschool/issueairpreach.vue')
  },
  // 企业线上申请参会列表
  {
    path: '/airapplyforlist',
    name: 'airapplyforlist',
    component: () => import('../views/enterprisehome/applyfor/airapplyforlist.vue')
  },
  // 企业线下申请参会列表
  {
    path: '/offapplyforlist',
    name: 'offapplyforlist',
    component: () => import('../views/enterprisehome/applyfor/offapplyforlist.vue')
  },
  // 企业进入会场线上
  {
    path: '/enterthevenueonline',
    name: 'enterthevenueonline',
    component: () => import('../components/enterthevenueonline.vue')
  },
  // 企业进入会场
  {
    path: '/enterthevenue',
    name: 'enterthevenue',
    component: () => import('../components/enterthevenue.vue')
  },
  // 学校进入会场线上
  {
    path: '/enterthevenueonlineschool',
    name: 'enterthevenueonlineschool',
    component: () => import('../components/enterthevenueonlineschool.vue')
  },
  // 学校进入会场
  {
    path: '/enterthevenueschool',
    name: 'enterthevenueschool',
    component: () => import('../components/enterthevenueschool.vue')
  },

  // 企业宣讲会详情
  {
    path: '/Propagandameetingdetail',
    name: 'Propagandameetingdetail',
    component: () => import('../components/Propagandameetingdetail.vue')
  },
  // 企业宣讲会详情线上
  {
    path: '/Propagandameetingdetailonline',
    name: 'Propagandameetingdetailonline',
    component: () => import('../components/Propagandameetingdetailonline.vue')
  },
  // 企业双选会详情
  {
    path: '/Doubleelectiondetail',
    name: 'Doubleelectiondetail',
    component: () => import('../components/Doubleelectiondetail.vue')
  },
  // 企业空中双选会详情
  {
    path: '/Doubleelectiondetailonline',
    name: 'Doubleelectiondetailonline',
    component: () => import('../components/Doubleelectiondetailonline.vue')
  },
  // 学校宣讲会详情
  {
    path: '/Propagandameetingdetailschool',
    name: 'Propagandameetingdetailschool',
    component: () => import('../components/Propagandameetingdetailschool.vue')
  },
  // 学校宣讲会详情线上
  {
    path: '/Propagandameetingdetailonlineschool',
    name: 'Propagandameetingdetailonlineschool',
    component: () => import('../components/Propagandameetingdetailonlineschool.vue')
  },
  // 学校双选会详情
  {
    path: '/Doubleelectiondetailschool',
    name: 'Doubleelectiondetailschool',
    component: () => import('../components/Doubleelectiondetailschool.vue')
  },

  // 学校空中双选会详情
  {
    path: '/Doubleelectiondetailonlineschool',
    name: 'Doubleelectiondetailonlineschool',
    component: () => import('../components/Doubleelectiondetailonlineschool.vue')
  },
  // 学校就业指导列表
  {
    path: '/Employmentcourseslist',
    name: 'Employmentcourseslist',
    component: () => import('../views/schoolpage/Employmentcourses/list.vue')
  },
  // 课程详情
  {
    path: '/Employmentcoursesdetailpage',
    name: 'Employmentcoursesdetailpage',
    component: () => import('../views/schoolpage/Employmentcourses/detailpage.vue')
  },
  // 学校管理
  {
    path: '/studentmanagementlist',
    name: 'studentmanagementlist',
    component: () => import('../views/schoolpage/studentmanagement/list.vue')
  },
  // 修改学生信息
  {
    path: '/addform',
    name: 'addform',
    component: () => import('../views/schoolpage/studentmanagement/addform.vue')
  },
  // 就业协议书填报审核列表
  {
    path: '/Agreementreview',
    name: 'Agreementreview',
    component: () => import('../views/schoolpage/Employmentfilling/Agreementreview.vue')
  },
  // 就业协议书审核详情
  {
    path: '/Agreementaudit',
    name: 'Agreementaudit',
    component: () => import('../views/schoolpage/Employmentfilling/Agreementaudit.vue')
  },
  // 就业推荐书审核列表
  {
    path: '/recommendationform',
    name: 'recommendationform',
    component: () => import('../views/schoolpage/Employmentfilling/recommendationform.vue')
  },
  // 就业填报审核列表
  {
    path: '/Informationfilling',
    name: 'Informationfilling',
    component: () => import('../views/schoolpage/Employmentfilling/Informationfilling.vue')
  },
  // 就业填报审核详情
  {
    path: '/Informationfillingdetail',
    name: 'Informationfillingdetail',
    component: () => import('../views/schoolpage/Employmentfilling/Informationfillingdetail.vue')
  },
  // 添加视频课程
  {
    path: '/videocourse',
    name: 'videocourse',
    component: () => import('@/views/schoolpage/addcourse/videocourse.vue')
  },
  // 推荐表线详情
  {
    path: '/auditdetail',
    name: 'auditdetail',
    component: () => import('@/views/schoolpage/Employmentfilling/auditdetail.vue')
  },
  // 企业主页
  {
    path: '/enterindex',
    name: 'enterindex',
    // component: Home,
    component: () => import('@/views/enterprisehome/enterindex/index.vue')
  },
  // 学校企业主页
  {
    path: '/enterindexschool',
    name: 'enterindexschool',
    // component: Home,
    component: () => import('@/views/schoolpage/enterindex/index.vue')
  },
  // 学校工作台
  {
    path: '/workbench',
    name: 'workbench',
    // component: Home,
    component: () => import('@/views/schoolpage/workbench/workbench.vue')
  },
  // 就业活动
  {
    path: '/activitieslist',
    name: 'activitieslist',
    component: () => import('@/views/schoolpage/activities/activitieslist.vue')
  },
  // 新增就业活动
  {
    path: '/addactive',
    name: 'addactive',
    component: () => import('@/views/schoolpage/activities/addactive.vue')
  },
  // 报名人数
  {
    path: '/activedetail',
    name: 'activedetail',
    component: () => import('@/views/schoolpage/activities/activedetail.vue')
  },
  // 老师咨询

  {
    path: '/consultingServicedetail',
    name: 'consultingServicedetail',
    component: () => import('@/views/schoolpage/consultingService/consultingServicedetail.vue')
  },
  // 信息列表
  {
    path: '/addinfolist',
    name: 'addinfolist',
    component: () => import('@/views/schoolpage/addinfo/addinfolist.vue')
  },
  // 信息发布
  {
    path: '/addinfodetail',
    name: 'addinfodetail',
    component: () => import('@/views/schoolpage/addinfo/addinfodetail.vue')
  },
  // 信息预览
  {
    path: '/addinfoview',
    name: 'addinfoview',
    component: () => import('@/views/schoolpage/addinfo/addinfoview.vue')
  },
  // 轮播图列表
  {
    path: '/rotationChartlist',
    name: 'rotationChartlist',
    component: () => import('@/views/schoolpage/rotationChart/rotationChartlist.vue')
  },
  // 轮播图发布
  {
    path: '/rotationChartdetail',
    name: 'rotationChartdetail',
    component: () => import('@/views/schoolpage/rotationChart/rotationChartdetail.vue')
  }
]

const router = new VueRouter({
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
