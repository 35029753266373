import { axios } from '@/utils/request'
// import md5 from 'js-md5'
/**
 * 登录
 *
 * @author xuzhanfu
 * @date 2020/5/26 19:06
 */
export function login (data) {
  return axios({
    url: '/mate-uaa/oauth/token',
    method: 'post',
    // headers: {
    //   key: 'acbedcf416f34915aa4964bb5629e37d',
    //   code: 0
    // },
    params: {
      mobile: data.mobile,
      code: data.code,
      // username: 18546549887,
      // password: md5('w123123'),
      grant_type: 'sms',
      scope: 'all',
      role: '103'
    }
  })
}

/**
 * @description 发送验证码  zyz
 * @param {*} params
 * @returns
 */
export function getVerificationCode (mobile) {
  return axios({
    url: '/mate-uaa/auth/sms-code',
    method: 'GET',
    params: {
      mobile
    }
  })
}

/**
 * @description 查询用户所在企业  zyz
 * @param {*} userId
 * @returns
 */
export function queryUsersEnterprises (params) {
  return axios({
    url: '/jobfair/userenterprise/getEnterpris',
    method: 'GET',
    params,
    headers: {
      'Mate-Auth': 'Bearer ' + JSON.parse(localStorage.getItem('pro__Access-Token')).value
      // Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  })
}

/**
 * @description 学生投递简历  yyl
 * @param {*} data
 * @returns
 */
export function setResume (data) {
  return axios({
    url: '/jypt/resume/sendResume',
    method: 'POST',
    data,
    headers: {
      'Mate-Auth': 'Bearer ' + JSON.parse(localStorage.getItem('pro__Access-Token')).value
    }
  })
}

/**
 * 获取登录用户信息
 *
 * @author pangu
 * @date 2020/5/26 19:08
 */
export function getLoginUser (id) {
  return axios({
    url: '/mate-uaa/auth/get/user',
    method: 'get',
    params: { id },
    headers: {
      'Content-Type': 'application / x - www - form - urlencoded',
      'Mate-Auth': 'Bearer ' + localStorage.getItem('Access-Token')
    }
  })
}

/**
 * @description  获取所有字典列表  wjh
 * @param {*} params
 * @returns
 */
export function sysDictTypeTree (params) {
  return axios({
    url: '/mate-system/dict/tree',
    method: 'GET',
    params
    // headers: {
    //   'Content-Type': 'application / x - www - form - urlencoded',
    //   'Mate-Auth': 'Bearer ' + localStorage.getItem('Access-Token')
    // }
  })
}

/**
 * @description  获取所有字典列表  zyz
 * @param {*} params
 * @returns
 */
export function getsysDictTypeTree (params) {
  return axios({
    url: '/jobfair/common/data/dict.json',
    method: 'GET',
    params
  })
}

/**
 * @description  获取所有双选会  wjh
 * @param {*} params
 * @returns
 */
export function getdoubleSelections (params) {
  return axios({
    url: '/jobfair/jobfair/page',
    method: 'GET',
    params
  })
}

/**
 *@description  最新职位  wjh
 * @param {*} params
 * @returns
 */
export function getpositionpage (params) {
  return axios({
    url: '/jobfair/positioninfo/studentPage',
    method: 'GET',
    params
  })
}

/**
 * @description  专业  wjh
 * @param {*} params
 * @returns
 */
export function queryposition (params) {
  return axios({
    url: '/jobfair/common/data/position.json',
    method: 'GET',
    params
  })
}

/**
 * @description  获取匹配职位  wjh
 * @param {*} params
 * @returns
 */
// export function newPosition (data) {
//   return axios({
//     url: '/jobfair/positioninfo/student-recommend-page',
//     method: 'GET',
//     data
//   })
// }

/**
 * @description 查询双选会详情  wjh
 * @param {*} id
 * @returns
 */
export function checkTheDetails (id) {
  return axios({
    url: '/jobfair/jobfair/page',
    method: 'GET',
    params: {
      id
    }
  })
}
/**
 * @description   获取职位详情  yyl
 * @param {Object} id
 */
export function PositionDetails (id) {
  return axios({
    url: '/jobfair/positioninfo/get',
    method: 'GET',
    params: {
      id
    }
  })
}

/**
 * @description 查询职位列表  zyz
 * @param {*} id
 * @returns
 */
export function allpositionlist (params) {
  return axios({
    url: '/jobfair/positioninfo/query',
    method: 'GET',
    params
  })
}

/**
 * @description 城市树列表  zyz
 * @param {*} id
 * @returns
 */
export function citytreelist (params) {
  return axios({
    url: '/jobfair/common/data/city.json',
    method: 'GET',
    params
  })
}

/**
 * @description 热门城市列表  zyz
 * @param {*} id
 * @returns
 */
export function htocitytreelist (params) {
  return axios({
    url: '/jobfair/common/data/hotcity.json',
    method: 'GET',
    params
  })
}

/**
 * @description 获取薪资范围  zyz
 * @param {*} id
 * @returns
 */
export function getSalaryfw (params) {
  return axios({
    url: '/jobfair/common/data/range.json',
    method: 'GET',
    params
  })
}

/**
 * @description  获取企业注册信息page   wjh
 * @param {*} params
 * @returns
 */
export function getpageEnterpriseInfo (id) {
  return axios({
    url: '/jobfair/enterprise/get',
    method: 'GET',
    params: {
      id
    }
  })
}

/**
 * @description   获取企业详情  yyl
 * @param {Object} id
 */
export function getFocusonenterprise (enterpriseId) {
  return axios({
    url: '/jobfair/studententerprise/get',
    method: 'GET',
    params: {
      enterpriseId
    }
  })
}
/**
 * @description  公司下所有职位   wjh
 * @param {*} id
 * @returns
 */
export function jobListings (params) {
  return axios({
    url: '/jobfair/positioninfo/page',
    method: 'GET',
    params
  })
}

/**
 * @description  收藏职位  yyl
 * @param {Object} data
 */
export function collectposition (params) {
  return axios({
    url: '/jobfair/positionFavorite/set',
    method: 'POST',
    params
  })
}
/**
 * @description  取消收藏
 * @param {Object} ids
 */
export function delcollectposition (ids) {
  return axios({
    url: '/jobfair/positionFavorite/del',
    method: 'POST',
    params: {
      ids
    }
  })
}
/**
 * @description   获取公司其他的职位  wjh
 * @param {Object} params
 */
export function getotherjobpage (params) {
  return axios({
    url: '/jobfair/positioninfo/pageEnterprisePosition',
    method: 'GET',
    params
  })
}
/**
 * @description  获取学历学院专业 yyl
 * @param {*} parameter
 * @returns
 */
export function getrecordtree (education) {
  return axios({
    url: '/jobfair/dict/college-profession/tree',
    method: 'get',
    params: {
      education
    }
  })
}

/**
 * @description  获取信息分页  wjh
 * @param {*} params
 * @returns
 */
export function getjournalismpage (params) {
  return axios({
    url: '/ghorns-job/cms/news/page',
    method: 'GET',
    params
  })
}

/**
 * @description  获取新闻类别 wjh
 * @param {*} params
 * @returns
 */
export function gettype () {
  return axios({
    url: '/ghorns-job/cms/news/category/page',
    method: 'GET'
  })
}
/**
 * @description  新增信息 wjh
 * @param {*} params
 * @returns
 */
export function setjournalism (data) {
  return axios({
    url: '/ghorns-job/cms/news/set',
    method: 'POST',
    data
  })
}

/**
 * @description  获取信息  wjh
 * @param {*} id
 * @returns
 */
export function getjournalism (id) {
  return axios({
    url: '/ghorns-job/cms/news/get',
    method: 'GET',
    params: {
      id
    }
  })
}
/**
 * @description  删除信息  wjh
 * @param {*} ids
 * @returns
 */
export function deljournalism (ids) {
  return axios({
    url: '/ghorns-job/cms/news/del',
    method: 'POST',
    params: {
      ids
    }
  })
}

/**
 * @description  获取轮播图分页  wjh
 * @param {*} params
 * @returns
 */
export function getimgpage (params) {
  return axios({
    url: '/ghorns-job/cmsRotationChart/page',
    method: 'GET',
    params
  })
}
/**
 * @description  删除轮播图  wjh
 * @param {*} ids
 * @returns
 */
export function delimg (ids) {
  return axios({
    url: '/ghorns-job/cmsRotationChart/del',
    method: 'POST',
    params: {
      ids
    }
  })
}
/**
 * @description  获取轮播图  wjh
 * @param {*} id
 * @returns
 */
export function getimg (id) {
  return axios({
    url: '/ghorns-job/cmsRotationChart/get',
    method: 'GET',
    params: {
      id
    }
  })
}
/**
 * @description  新增轮播图 wjh
 * @param {*} params
 * @returns
 */
export function setimg (data) {
  return axios({
    url: '/ghorns-job/cmsRotationChart/set',
    method: 'POST',
    data
  })
}
