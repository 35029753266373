<template>
  <div class="info">
    <!-- <div class="title">学校端</div>
    <p class="menu">职位列表</p>
    <p class="menu">职位审核列表</p>
    <p class="menu">企业列表</p>
    <p class="menu">企业审核列表</p>
    <p class="menu" @click="xxkzxjh">空中宣讲会列表</p>
    <p class="menu" @click="xxxxxjh">线下宣讲会列表</p>
    <p class="menu" @click="xxkzsxh">空中双选会列表</p>
    <p class="menu" @click="xxxxsxh">线下双选会列表</p>
    <p class="menu">宣讲会审核列表</p>
    <p class="menu">双选会审核列表</p>
    <p class="menu" @click="fbxsxxh">发布线上双选会</p>
    <p class="menu" @click="fbxxxxh">发布线下双选会</p>
    <div class="title">企业端</div>
    <p class="menu">职位列表</p>
    <p class="menu" @click="qykzxjh">空中宣讲会列表</p>
    <p class="menu" @click="qyxxxjh">线下宣讲会列表</p>
    <p class="menu" @click="qykzsxh">空中双选会列表</p>
    <p class="menu" @click="qyxxsxh">线下双选会列表</p>
    <p class="menu" @click="fbxsxjh">发布线上宣讲会</p>
    <p class="menu" @click="fbxxxjh">发布线下宣讲会</p>
    <p class="menu" @click="zwshlb">职位审核列表</p>
    <p class="menu" @click="qylb">企业列表</p>
    <p class="menu" @click="qyshlb">企业审核列表</p>
    <p class="menu" @click="xjhshlb">宣讲会审核列表</p>
    <p class="menu" @click="sxhshlb">双选会审核列表</p>
    <p class="menu" @click="sxhxqxs">空中双选会详情</p>
    <p class="menu" @click="sxhxq">双选会详情</p>
    <p class="menu" @click="xjhxqxs">宣讲会详情空宣</p>
    <p class="menu" @click="xjhxq">宣讲会详情线下</p>
    <p class="menu" @click="xssqchlb">线上申请参会列表</p>
    <p class="menu" @click="xxsqchlb">线下申请参会列表</p>
    <p class="menu" @click="jrhcxs">进入会场空双</p>
    <p class="menu" @click="jrhc">进入会场</p>
    <p class="menu" @click="jrhcxsdy">进入会场空双第一个页面</p>
    <p class="menu" @click="jrhcdy">进入会场第一个页面</p> -->
    <p class="menu" @click="jumpstudent">
        学校端
    </p>
    <p class="menu" @click="jumpenter">
        企业端
    </p>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    xssqchlb () {
      this.$router.push({
        path: '/airapplyforlist'
      })
    },
    xxsqchlb () {
      this.$router.push({
        path: '/offapplyforlist'
      })
    },
    qykzxjh () {
      this.$router.push({
        path: '/airdoublepreachlist'
      })
    },
    qyxxxjh () {
      this.$router.push({
        path: '/offinedoublepreachlist'
      })
    },
    qykzsxh () {
      this.$router.push({
        path: '/airdoubleselectlist'
      })
    },
    qyxxsxh () {
      this.$router.push({
        path: '/offinedoubleselectlist'
      })
    },
    xxkzxjh () {
      this.$router.push({
        path: '/airdoublepreachlistschool'
      })
    },
    xxxxxjh () {
      this.$router.push({
        path: '/offinedoublepreachlistschool'
      })
    },
    xxkzsxh () {
      this.$router.push({
        path: '/airdoubleselectlistschool'
      })
    },
    xxxxsxh () {
      this.$router.push({
        path: '/offinedoubleselectlistschool'
      })
    },
    fbxsxjh () {
      this.$router.push({
        path: '/issueairpreach'
      })
    },
    fbxxxjh () {
      this.$router.push({
        path: '/issueoffinepreach'
      })
    },
    fbxsxxh () {
      this.$router.push({
        path: '/issueairdouble'
      })
    },
    fbxxxxh () {
      this.$router.push({
        path: '/issueairpreachschool'
      })
    },
    zwshlb () {
      this.$router.push('/Auditlistschool')
    },
    qylb () {
      this.$router.push('/enterpriceschool')
    },
    qyshlb () {
      this.$router.push('/enterauditlistschool')
    },
    xjhshlb () {
      this.$router.push('/propauditlistschool')
    },
    sxhshlb () {
      this.$router.push('/doubleauditlistschool')
    },
    xjhxqxs () {
      this.$router.push('/Propagandameetingdetailonline')
    },
    xjhxq () {
      this.$router.push('/Propagandameetingdetail')
    },
    sxhxqxs () {
      this.$router.push('/Doubleelectiondetailonline')
    },
    sxhxq () {
      this.$router.push('/Doubleelectiondetail')
    },
    jrhcxs () {
      this.$router.push('/enterthevenueonline')
    },
    jrhc () {
      this.$router.push('/enterthevenue')
    },
    jrhcxsdy () {
      this.$router.push('/enterthevenueonline')
    },
    jrhcdy () {
      this.$router.push('/enterthevenue')
    },
    jumpstudent () {
      this.$router.push('/positionlistschool')
      localStorage.setItem('menu', 6)
    },
    jumpenter () {
      this.$router.push('/positionlist')
      localStorage.setItem('menu', 1)
    }
  }
}
</script>
<style lang="less" scoped>
.info{
  padding: 20px;
  width: 1200px;
  margin: 0 auto;
  .title{
    clear: both;
    padding: 30px 0;
    color: #222;
    font-size: 18px;
    font-weight: 500;
  }
  .menu{
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    height: 80px;
    width: 80px;
    color: #666;
    font-size: 14px;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #666;
    border-radius: 4px;
    float: left;
    margin-left: 20px;
  }
}
</style>
