<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {
  getsysDictTypeTree
} from '@/api/self'
export default {
  data () {
    return {

    }
  },
  mounted () {
    // this.getsysDictTypeTree()
    // this.getsysDictTypexl()
    // this.getsysDictTypesshy()
    // this.getsysDictTypedwxz()
    // this.getsysDictTypezzmm()
    // this.getsysDictTypedwgm()
  },
  methods: {
    // 获取职位类别
    getsysDictTypeTree () {
      const data = {
        code: 'positionType'
      }
      getsysDictTypeTree(data).then((res) => {
        localStorage.setItem('zwlb', JSON.stringify(res.data))
        // if (res.code === 200 || res.code === '200') {
        //   this.industry = res.data
        // }
      })
        .catch()
    },
    // 获取学历
    getsysDictTypexl () {
      const data = {
        code: 'Education'
      }
      getsysDictTypeTree(data).then((res) => {
        localStorage.setItem('xl', JSON.stringify(res.data))
        // if (res.code === 200 || res.code === '200') {
        //   this.industry = res.data
        // }
      })
        .catch()
    },
    // 获取单位行业
    getsysDictTypesshy () {
      const data = {
        code: 'DWHY'
      }
      getsysDictTypeTree(data).then((res) => {
        console.log(res)
        localStorage.setItem('sshy', JSON.stringify(res.data))
        // if (res.code === 200 || res.code === '200') {
        //   this.industry = res.data
        // }
      })
        .catch()
    },

    // 获取单位性质
    getsysDictTypedwxz () {
      const data = {
        code: 'DWXZ'
      }
      getsysDictTypeTree(data).then((res) => {
        console.log(res)
        localStorage.setItem('dwxz', JSON.stringify(res.data))
        // if (res.code === 200 || res.code === '200') {
        //   this.industry = res.data
        // }
      })
        .catch()
    },
    // 获取政治面貌
    getsysDictTypezzmm () {
      const data = {
        code: 'ZZMM'
      }
      getsysDictTypeTree(data).then((res) => {
        console.log(res)
        localStorage.setItem('zzmm', JSON.stringify(res.data))
        // if (res.code === 200 || res.code === '200') {
        //   this.industry = res.data
        // }
      })
        .catch()
    },
    // 获取单位规模
    getsysDictTypedwgm () {
      const data = {
        code: 'scale'
      }
      getsysDictTypeTree(data).then((res) => {
        console.log(res)
        localStorage.setItem('scale', JSON.stringify(res.data))
        // if (res.code === 200 || res.code === '200') {
        //   this.industry = res.data
        // }
      })
        .catch()
    }
  }
}
</script>

<style lang="less">
// *{
//   margin: 0;
//   padding: 0;
//   //background-color: #F0F2F5;
// }
 li {
      /* 去掉li前面的小圆点 */
      list-style: none;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.w{
  width: 1200px;
  margin: auto;
}
body{
  .tooltext{
    span{
      &:hover{
        color: #4F87FE;
      }
    }
  }
}
</style>
<style scoped lang='less'>
/deep/.ql-size-small{
  font-size: 14px;
}
/deep/.ql-size-large{
  font-size: 20px;
}
/deep/.ql-size-huge{
  font-size: 32px;
}
/deep/.ql-align-center{
  text-align: center;
}
/deep/.ql-align-right{
  text-align:right ;
}
/deep/.ql-align-justify{
  text-align:justify ;
}
  /deep/.ql-indent-1{
  text-indent:2em;
  }
  /deep/.ql-indent-2{
  text-indent:4em;
  }
  /deep/.ql-indent-3{
  text-indent:6em;
  }
  /deep/.ql-indent-4{
  text-indent:8em;
  }
</style>
