import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 按模板导出pdf
import htmlToPdf from '../src/utils/htmlToPdf'
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VideoPlayer)
import WSchedule from 'wschedule'
import 'wschedule/dist/wschedule.css'
import echarts from 'echarts'
// 时间插件
import dayjs from 'dayjs'
Vue.prototype.$echarts = echarts

// 引入echarts
Vue.use(WSchedule)
Vue.prototype.dayjs = dayjs // 可以全局使用dayjs

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(htmlToPdf)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
